import React from "react"

import Layout from "../components/Layout"
import PageTitleSmall from "../components/PageTitleSmall"
import SEO from "../components/seo"
import PageContent from "../components/PageContent"
import img from "../images/img/bg-about.jpg"
import AboutBlock from "../components/AboutBlock"

const UberUns = () => (
  <Layout>
    <SEO title="Über Uns" />
    <PageTitleSmall title="Über Uns" img={img} />
    <AboutBlock />
  </Layout>
)

export default UberUns
